<template>
  <div class="closeBetaviewDetail">
    <el-form
      class="dialogForm"
      size="small"
      label-width="200px"
      style="height: 500px; overflow: scroll; overflow-x: hidden"
    >
      <el-form-item label="首发类别" class="requireClass">
        <el-radio-group
          disabled
          v-model="startingInfo.first_type"
          class="isDeleteArchive"
        >
          <el-radio :value="0">
            新游首发
            <el-tooltip
              content="游戏只接受新应用首发。"
              placement="top"
              effect="light"
            >
              <i
                class="el-icon-warning-outline tooltip-position"
                style="font-size: 20px; color: rgb(204, 204, 204)"
              ></i>
            </el-tooltip>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="首发时间" class="requireClass">
        <el-date-picker
          disabled
          size="small"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          type="datetime"
          prefix-icon="el-icon-date"
          v-model="startingInfo.firstTime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
        <el-tooltip
          content="建议与您的游戏开服时间一致。"
          placement="top"
          effect="light"
        >
          <i
            class="el-icon-warning-outline tooltip-position"
            style="font-size: 20px; color: rgb(204, 204, 204)"
          ></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="预下载推广时间">
        <el-date-picker
          disabled
          class=""
          size="small"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          type="datetime"
          prefix-icon="el-icon-date"
          v-model="startingInfo.preDownloadTime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="研发公司">
        <el-input
          disabled
          show-word-limit
          maxlength="50"
          v-model="startingInfo.company"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="评测APK包：">
        <el-input
          disabled
          v-model="startingInfo.testApkUrl"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="评测高等级账号">
        <el-input
          disabled
          show-word-limit
          maxlength="50"
          v-model="startingInfo.testAccount"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="发布国家及地区：" class="requireClass">
        <el-cascader
          clearable
          size="small"
          :disabled="true"
          :options="areaList"
          v-model="releaseArea"
          placeholder="请选择发布国家或地区"
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="商务联系方式：" class="requireClass">
        <el-input
          disabled
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          show-word-limit
          maxlength="100"
          v-model="startingInfo.businessContact"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="玩家客服联系方式：" class="requireClass">
        <el-input
          disabled
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          show-word-limit
          maxlength="100"
          v-model="startingInfo.csContact"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          disabled
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          v-model="startingInfo.remark"
          show-word-limit
          maxlength="200"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "viewDetail",
  props: {
    startingInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    propReleaseArea: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {
    this.getArrReleaseArea(this.propReleaseArea);
    this.$store.dispatch("updateAreaListList");
  },
  data() {
    return {
      releaseArea: [],
      areaProps: {
        value: "countryId",
        label: "countryName",
        children: "honorAppCountryList",
        multiple: true,
      },
    };
  },
  computed: {
    ...mapGetters(["areaList"]),
  },
  methods: {
    // 根据id获取完整的id数组（包含上下级关系）
    async getArrReleaseArea(idList = []) {
      const areaList = await this.$store.dispatch("updateAreaListList");
      const arr = [];
      if (!(idList.length === 0 || areaList.length === 0)) {
        idList.map((key, index) => {
          const parentkey = this.getParentKey(key, areaList);
          arr[index] = [parentkey, key];
        });
      }
      this.releaseArea = arr;
    },
    // 根据传入的孩子节点的key获取父节点key
    getParentKey(childrenKey, list = []) {
      let parentItem = "";
      for (let item of list) {
        const childrenList = item[this.areaProps.children];
        if (childrenList && childrenList.length !== 0) {
          const obj = childrenList.find(
            (item1) => item1[this.areaProps.value] === childrenKey
          );
          if (obj) {
            parentItem = item;
            break;
          }
        }
      }
      return parentItem[this.areaProps.value];
    },
  },
  watch: {
    propReleaseArea(idList) {
      this.getArrReleaseArea(idList);
    },
  },
};
</script>
<style scoped lang="scss">
.closeBetaviewDetail {
  .release-title {
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  /deep/.requireClass {
    .el-form-item__label::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
</style>
<style lang="scss">
@import "~@/common/css/component.scss"; // 全局公共css
.dialogForm {
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 500px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
  .el-date-editor.el-input.el-date-editor--datetime {
    width: 234px;
  }
}
</style>
